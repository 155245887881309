import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";

const QrCodeScanner = (props) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  const [result, setResult] = useState("No result");

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const canvas2 = canvasRef2.current;
    const context2 = canvas2.getContext('2d');

    const qrScanner = new QrScanner(
      video,
      (result) => props.onResult(result.data),
      {
        returnDetailedScanResult: true,
        highlightScanRegion: false,
        highlightCodeOutline: false,
        maxScansPerSecond: 60,
        scanRegion: setCalculateScanRegion()
      }
    );

    // qrScanner.setGrayscaleWeights(0.1, 0.3, 0.5, false); // Fine-tuned weights for better contrast
    qrScanner.setInversionMode("both");

    function setCalculateScanRegion() {
      const minSize = Math.min(video.videoWidth, video.videoHeight);
      const scanRegionSize = Math.round(minSize * 1); // 60% of the smallest dimension
      return {
        x: (video.videoWidth - scanRegionSize) / 2,
        y: (video.videoHeight - scanRegionSize) / 2,
        width: scanRegionSize,
        height: scanRegionSize,
        downScaledWidth: scanRegionSize,
        downScaledHeight: scanRegionSize,
      };
    }
    // alert("QrScanner.listCameras()",QrScanner.listCameras(true).then(res => alert(JSON.stringify(res))));
    QrScanner.listCameras(true).then((res)=>{
        let selectedcamera ="environment" ;
        res.map(e=>{
            if(e.label.includes("facing back")){
                selectedcamera = e.id
            }
        })
    qrScanner.setCamera(selectedcamera).then(qrScanner.start())
    })
    
    // async; without requesting camera labels

    // qrScanner.setCamera("environment");

    const enhanceContrastAndInvert = (imageData) => {
        const data = imageData.data;
        const contrast = 10; // Maximum contrast
        const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
  
        for (let i = 0; i < data.length; i += 4) {
          // const r = data[i];
          // const g = data[i + 1];
          // const b = data[i + 2];
  
          // // Apply contrast enhancement
          // const newR = factor * (r - 128) + 128;
          // const newG = factor * (g - 128) + 128;
          // const newB = factor * (b - 128) + 128;
  
          // // Convert to grayscale
          // const gray = 0.299 * newR + 0.587 * newG + 0.114 * newB;
  
          // // Invert colors
          // const invertedGray = 255 - gray;
          // // const invertedGray =  gray;
  
          // data[i] = invertedGray;
          // data[i + 1] = invertedGray;
          // data[i + 2] = invertedGray;
        }
        return imageData;
      };
    // const enhanceContrastAndInvert = (imageData) => {
    //     const data = imageData.data;
    //     const contrast = 70; // Maximum contrast
    //     const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
      
    //     // Apply sharpening filter
    //     const sharpenKernel = [
    //       [0, -1, 0],
    //       [-1, 5, -1],
    //       [0, -1, 0]
    //     ];
      
    //     const width = imageData.width;
    //     const height = imageData.height;
      
    //     // Create a new ImageData object to store the sharpened data
    //     const newImageData = new ImageData(width, height);
    //     const newData = newImageData.data;
      
    //     // Convolution function for applying filter
    //     const applyKernel = (x, y) => {
    //       let r = 0, g = 0, b = 0;
    //       const kernelSize = 3;
    //       const offset = Math.floor(kernelSize / 2);
      
    //       for (let ky = 0; ky < kernelSize; ky++) {
    //         for (let kx = 0; kx < kernelSize; kx++) {
    //           const px = x + kx - offset;
    //           const py = y + ky - offset;
      
    //           if (px >= 0 && px < width && py >= 0 && py < height) {
    //             const i = ((py * width) + px) * 4;
    //             const weight = sharpenKernel[ky][kx];
      
    //             r += data[i] * weight;
    //             g += data[i + 1] * weight;
    //             b += data[i + 2] * weight;
    //           }
    //         }
    //       }
    //       return { r: Math.min(255, Math.max(0, r)), g: Math.min(255, Math.max(0, g)), b: Math.min(255, Math.max(0, b)) };
    //     };
      
    //     // Apply filter to each pixel
    //     for (let y = 0; y < height; y++) {
    //       for (let x = 0; x < width; x++) {
    //         const { r, g, b } = applyKernel(x, y);
            
    //         const i = ((y * width) + x) * 4;
      
    //         // Apply contrast enhancement
    //         const newR = factor * (r - 128) + 128;
    //         const newG = factor * (g - 128) + 128;
    //         const newB = factor * (b - 128) + 128;
      
    //         // Convert to grayscale
    //         const gray = 0.299 * newR + 0.587 * newG + 0.114 * newB;
      
    //         // Invert colors
    //         const invertedGray = 255 - gray;
      
    //         newData[i] = invertedGray;
    //         newData[i + 1] = invertedGray;
    //         newData[i + 2] = invertedGray;
    //         newData[i + 3] = 255; // Full alpha
    //       }
    //     }
      
    //     return newImageData;
    //   };
    const originalScanFrame = qrScanner._scanFrame.bind(qrScanner);
    qrScanner._scanFrame = () => {
      if (!qrScanner._active || video.paused || video.ended) return;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      imageData = enhanceContrastAndInvert2(imageData); // Adjust contrast value as needed
      context.putImageData(imageData, 0, 0);

      qrScanner.$canvas = canvas;

      qrScanner._qrEnginePromise.then(engine => {
        return QrScanner.scanImage(canvas, {
          scanRegion: qrScanner._scanRegion,
          qrEngine: engine,
          canvas: qrScanner.$canvas,
        });
      }).then(result => {
        // alert(JSON.stringify(result))
        if (qrScanner._onDecode) {
          qrScanner._onDecode(result);
        }
      }).catch(err => {
        qrScanner._onDecodeError(err);
      }).finally(() => {
        originalScanFrame();
      });
    };
    ///canvas2
    const enhanceContrastAndInvert2 = (imageData) => {
        const data = imageData.data;
        const contrast = 70; // Maximum contrast
        const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
  
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
  
          // Apply contrast enhancement
          const newR = factor * (r - 128) + 128;
          const newG = factor * (g - 128) + 128;
          const newB = factor * (b - 128) + 128;
  
          // Convert to grayscale
          const gray = 0.299 * newR + 0.587 * newG + 0.114 * newB;
  
          // Invert colors
          const invertedGray = 255 - gray;
          // const invertedGray = gray;
  
          data[i] = invertedGray;
          data[i + 1] = invertedGray;
          data[i + 2] = invertedGray;
        }
        return imageData;
      };
  
    const drawFrame = () => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvas2.width = video.videoWidth;
          canvas2.height = video.videoHeight;
          context2.drawImage(video, 0, 0, canvas2.width, canvas2.height);
          const imageData = context2.getImageData(0, 0, canvas2.width, canvas2.height);
          const enhancedImageData = enhanceContrastAndInvert2(imageData);
          context2.putImageData(enhancedImageData, 0, 0);
        }
        requestAnimationFrame(drawFrame);
      };
  
      requestAnimationFrame(drawFrame);
  }, [props]);

  return (
    <div className="relative w-full h-full">
      <video
        ref={videoRef}
        className="w-full h-full bg-gray-100 object-cover "
      ></video>
      <canvas
        ref={canvasRef}
        className="hidden"
        width={640}
        height={480}
      ></canvas>
      <canvas
        ref={canvasRef2}
        className="w-full h-full hidden"
        width={640}
        height={480}
      ></canvas>
      <div className=" absolute top-1/2  left-1/2 -translate-x-1/2 -translate-y-1/2  w-[calc(33%+1rem)] aspect-square bg-none border-8  border-dashed border-yellow-400">

      </div>
    </div>
  );
};

export default QrCodeScanner;
